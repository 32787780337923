<template>
    <div id="page-branch-list">
        <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full" v-for="(card, cardIndex) in listDataCard" :key="cardIndex">
                <statistics-card-line
                    :icon="card.icon"
                    type="area"
                    :color="card.color"
                    :height="61"
                    :hideChart="true"
                    :statistic="card.statistic"
                    :statisticTitle="card.title"
                    :checkPercent="card.check"
                    :iconRight="true"
                />
            </div>
        </div>

        <div class="vx-row mt-4 mb-12">
            <div class="vx-col w-full">
                <div class="vx-card p-4">
                    <div class="align-button">
                        <vs-button icon="icon-arrow-left" icon-pack="feather" type="border" color="warning" @click="$router.push('/user/transaction-history')">Quay lại</vs-button>
                    </div>
                    <div class="vx-col w-full"> 
                        <vs-table :noDataText="'Không có dữ liệu'" search stripe pagination :data="fileData" max-items="12">
                            <template slot="thead" >
                                <vs-th style="font-size:13px" class="bg-primary text-white" sort-key="email" v-for="(header, indexHeader) in headerTables" :key="indexHeader">
                                    <span>{{ header }}</span>
                                </vs-th>
                            </template>

                            <template slot-scope="{data}" >
                                <vs-tr :data="file" :key="fileIndex" v-for="(file, fileIndex) in data" >
                                    <vs-td>
                                        {{ file.index }}
                                    </vs-td>
                                    <vs-td>
                                        {{ file.fileName }}
                                    </vs-td>
                                    <vs-td>
                                        {{ file.description}}
                                    </vs-td>
                                    <vs-td>
                                        {{ file.createdDate }}
                                    </vs-td>
                                    <vs-td style="max-width:40px">
                                        <vx-tooltip v-if="file.status == 3" text="Đang xử lý" color="warning">
                                            <div class="lds-facebook">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </vx-tooltip>
                                        <vx-tooltip v-if="file.status == 1" text="Thành công" color="success" poisition="top">
                                                <feather-icon icon="CheckIcon" svgClasses="h-5 w-5 hover:text-success cursor-pointer" style="color: green;"/>
                                        </vx-tooltip>
                                        <vx-tooltip v-if="file.status == 2" text="Thất bại" color="danger" poisition="top">
                                                <feather-icon icon="XIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" style="color: red;"/>
                                        </vx-tooltip>
                                    </vs-td>
                                    <vs-td style="display: flex;">
                                        <vx-tooltip class="mt-1"  text="Xóa" color="danger" poisition="top" v-if="file.status !== 3">
                                            <feather-icon style="justify-content: center;vertical-align: bottom" icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmHandleRemoveFile(file)"/>
                                        </vx-tooltip>
                                        <vx-tooltip class="ml-4 mt-1" text="Tải xuống" color="success" poisition="top" v-if="file.status == 1">
                                            <feather-icon style="justify-content: center;vertical-align: bottom" icon="DownloadIcon" svgClasses="h-5 w-5 hover:text-success cursor-pointer" @click="downloadFile(file)"/>
                                        </vx-tooltip>
                                        <vx-tooltip class="ml-4 mt-1" text="Chi tiết" color="warning" poisition="top" v-if="file.status == 1">
                                            <feather-icon style="justify-content: center;vertical-align: bottom" icon="BarChartIcon" svgClasses="h-5 w-5 hover:text-warning cursor-pointer" @click="goDetail(file)"/>
                                        </vx-tooltip>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatisticsCardLine from "../../../../src/components/statistics-cards/StatisticsCardLine.vue"
import moment from "moment";
import authConfig from '@/../auth_config.json';
export default {
    components: {
        StatisticsCardLine
    },
    data() {
        return {
            listDataCard: [
                {statistic: 0, title: this.$t('navMenu.totalFileCompare'), icon: 'FileTextIcon', color: 'success', bg: '#fff',cardBackground: 'rgba(255, 61, 84, 0.15)', check: false},
                {statistic: 0, title: this.$t('navMenu.totalFileWait'), icon: 'FileTextIcon', color: 'primary', bg: '#fff',cardBackground: 'rgba(40, 199, 111, 0.15)', check: false},
                {statistic: 0, title: this.$t('navMenu.size'), icon: 'DatabaseIcon', color: 'warning', bg: '#fff',cardBackground: 'rgba(115, 103, 240, 0.15)', check: false},
            ],
            fileData: [],
             headerTables: [
                {title: 'ID'},
                {title: this.$t('navMenu.fileName')},
                {title: this.$t('navMenu.compareDate')},
                {title: this.$t('navMenu.description')},
                {title: this.$t('navMenu.status')},
                {title: this.$t('navMenu.actions')}
            ],
            file: {}
        }
    },
    computed: {
        chipColor() {
            return value => {
                if (value.status === 1) {
                    return "primary"
                } else if (value.status === 2) {
                    return "success"
                }
            }
        }
    },
    methods: {
        confirmHandleRemoveFile(file) {
            this.file = file;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Xác nhận xoá`,
                text: `Bạn có muốn xoá file không?`,
                accept: this.removeFileExport,
                acceptText: "Đồng ý",
                cancelText: "Huỷ",
            });
        },
        downloadFile: function(file){
            window.open(`${file.publicUrl}/${file.fid}`, "_blank");
        },
        removeFileExport: function(){
            this.$vs.loading();
            this.$crm.post(`/history/delete/${this.file.id}`).then(() => {
                this.initScreen();
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: 'Xoá file thành công',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: this.$t('message.error'),
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        getAllFile: function(){
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            let payload = {
                organizationId: userInfo.organization.id
            }
            this.$crm.post('/history/find-by-condition', payload).then((res) => {
                let result = res.data.sort((a, b) => b.id - a.id);
                let index = 0;
                result = result.map(el => {
                    el.createdDate = moment(new Date(el.modifiedDate)).format("DD/MM/YYYY HH:mm:ss");
                    index += 1;
                    el.index = index;
                    return el;
                })
                this.fileData = result;
            }).catch((err) => {
                return this.$vs.notify({
                    text: this.$t('message.error'),
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        getStatisticFile: function() {
            // this.$crm.post('/export-file-details/statistic-file', {}).then((res) => {
            //     this.listDataCard[0].statistic = res.data.totalFileSuccess;
            //     this.listDataCard[1].statistic = res.data.totalFilePending;
            //     this.listDataCard[2].statistic = this.formatData(res.data.totalStorageFile);
            // }).catch((err) => {
            //     return this.$vs.notify({
            //         text: this.$t('message.error'),
            //         text: err.response.data.message || err.response.data.error_description || err.response.data.error,
            //         iconPack: 'feather',
            //         icon: 'icon-alert-circle',
            //         color: 'danger'
            //     });
            // })
        },
        // formatData: function(num){
        //     if (num >= 1024*1024*1024) {
        //         return (num / (1024*1024*1024)).toFixed(1).replace(/\.0$/, '') + 'G';
        //     }
        //     if (num >= 1024*1024) {
        //         return (num / (1024*1024)).toFixed(1).replace(/\.0$/, '') + 'MB';
        //     }
        //     if (num >= 1024) {
        //         return (num / 1024).toFixed(1).replace(/\.0$/, '') + 'KB';
        //     }
        //     return num;
        // },
        initScreen(){
            this.getAllFile();
            this.getStatisticFile();
        },

        goDetail(file) {
            this.$router.push(`/user/checked-transaction-history-detail?id=${file.id}`)
        }
    },
    created() {
        this.initScreen();
    },
    mounted(){
        // this.$eventBus.$on('export-file-success', ($event) => {
        //     if ($event) this.initScreen();
        // })
    }
}
</script>

<style lang="scss" scpoped>
.align-button {
    position: absolute;
    // right: 240px;
    top: 28px;
}
@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 16px;
    }

    50%,
    100% {
        top: 16px;
        height: 4px;
    }
}
.lds-facebook {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    top: -3px;
    float: revert;
    padding-left: 25px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 4px;
    width: 3px;
    background: #FCB71E;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 4px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 10px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 16px;
    animation-delay: 0s;
}
</style>